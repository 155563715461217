.header {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 10vm;
    color: white;
    padding: 0px 14px;
}

.navbar-menu {
    display: flex;
    padding: 2px;
    margin: 5px;
}

.navbar-menu-item {
    border: 1px solid white;
    padding: 7px;
    margin: 3px;
    background-color: lightgray;
}

.navbar-menu-item:active {
    background-color: black;
    border: 1px solid gray;
}

.navbar-menu-item:hover {
    background-color: black;
    border: 1px solid darkgray;
}